import React, { Component } from 'react';
export default class Porfolio extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="portfolio">
      <div className="row">
        <div className="twelve columns collapsed">
          <h1>Some of My Projects</h1>
          <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
          {
            resumeData.portfolio && resumeData.portfolio.map((item)=>{
              return(
                <div className="columns portfolio-item">
                  <div className="item-wrap">
                  <a href={item.url} title={item.name}>
                      <img src={`${item.img}`} className="item-img"/>
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5>{item.name}</h5>
                          <p>{item.description}</p>
                          <a href={item.url}></a>
                        </div>
                      </div>
                      {/* <div className="link-icon"><i className="fa fa-link"></i></div> */}
                    </a>
                  </div>
                </div>
              )
            })
          }
          </div>
        </div>
      </div>
  </section>
        );
  }
}

  
// import React, { Component } from 'react';

// export default class Portfolio extends Component {
//   render() {

//     if(this.props.data){
//       var projects = this.props.data.projects.map(function(projects){
//         var projectImage = 'images/portfolio/'+projects.image;
//         return <div key={projects.title} className="columns portfolio-item">
//            <div className="item-wrap">
//             <a href={projects.url} title={projects.title}>
//                <img alt={projects.title} src={projectImage} />
//                <div className="overlay">
//                   <div className="portfolio-item-meta">
//                  <h5>{projects.title}</h5>
//                      <p>{projects.category}</p>
//                   </div>
//                 </div>
//               <div className="link-icon"><i className="fa fa-link"></i></div>
//             </a>
//           </div>
//         </div>
//       })
//     }

//     return (
//       <section id="portfolio">

//       <div className="row">

//          <div className="twelve columns collapsed">

//             <h1>Check Out Some of My Works.</h1>

//             <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
//                 {projects}
//             </div>
//           </div>
//       </div>
//    </section>
//     );
//   }
// }
