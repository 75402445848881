import React, { Component } from 'react';
export default class Resume extends Component {
    render(){
        let resumeData = this.props.resumeData;
        return(
        <section id="resume" >



<div className="row work">
            <div className="three columns header-col">
               <h1><span>Work Experience</span></h1>
            </div>

            <div className="nine columns main-col">
              {
                resumeData.work && resumeData.work.map((item) => {
                    return(
                        <div className="row item">
                            <div className="twelve columns">
                                <h3>{item.CompanyName}</h3>
                                <p className="info">
                                    {item.specialization}
                                    <span>&bull;</span> <em className="date">{item.StartDate} - {item.EndDate}</em>
                                </p>
                                <p>{item.Achievements1}<br></br>
                                {item.Achievements2}<br/>
                                {item.Achievements3}<br/>
                                {item.Achievements4}</p>
                            </div>
                        </div>
                    )
                })
              }
            </div> 
         </div>
        

         <div className="row education">

<div className="three columns header-col">
   <h1><span>Education</span></h1>
</div>

<div className="nine columns main-col">
  {
    resumeData.education && resumeData.education.map((item)=>{
      return(
        <div className="row item">
           <div className="twelve columns">
              <h3>{item.UniversityName}</h3>
              <p className="info">
              {item.specialization}
              <span>&bull;</span> <em className="date">{item.MonthOfPassing} {item.YearOfPassing}</em></p>
              <p>
              Relevant Courses :{item.RelevantCourses}
              </p>
           </div>
        </div>
      )
    })
  }
</div>
</div>

        <div className="row skill">
            <div className="three columns header-col">
                <h1><span>Skills</span></h1>
            </div>
            <div className="nine columns main-col">
            <p>{resumeData.skillsDescription}</p>
                <div className="bars">
                    <ul className="skills">
                    {
                        resumeData.skills && resumeData.skills.map((item) => {
                            return(
                                <li>
                                <span className={`bar-expand ${item.skillname.toLowerCase()}`}>
                                </span><em>{item.skillname}</em>
                                </li>
                            )
                        })
                    }
                    </ul>
                </div>
            </div>
        </div>
    </section>
);
}
}