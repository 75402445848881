import React, { Component } from 'react';
export default class Header extends Component {
    render() {
        let resumeData=this.props.resumeData;
        return (     
            <React.Fragment>
         <header id="home">
            <nav id="nav-wrap">
                <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
            <a className="mobile-btn" href="#" title="Hide navigation">Hide navigation</a>
                <ul id="nav" className="nav">
                    <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
                    <li><a className="smoothscroll" href="#about">About</a></li>
                    <li><a className="smoothscroll" href="#resume">Resume</a></li>
                    <li><a className="smoothscroll" href="#portfolio">Projects</a></li>
                    <li><a className="smoothscroll" href="#testimonials">Testimonials</a></li>
                    <li><a className="smoothscroll" href="#contact">Reach out to Me</a></li>
                    </ul>
                </nav>
                <div className="row banner">
                    <div className="banner-text">
                    <h2>I am <span id="underline" class="blink">_</span> </h2>
                    {/* <p style={{ marginBottom: '1em' }}>  </p> */}
                    <br></br>
                        <div class="line-1 anim-typewriter">
                            <h1 className="responsive-headline">{resumeData.name};</h1>
                        </div>
            
                    <h3>I am a {resumeData.role}.{resumeData.roleDescription}
                    </h3>
                    <hr/>
                        <ul className="social">
                            {
                                resumeData.socialLinks && resumeData.socialLinks.map(item =>{
                                return(
                                        <li key={item.name}>
                                            <a href={item.url} target="_blank"><i className={item.className}></i></a>
                                        </li>
                                        )
                                    }
                                )
                            }
                        </ul>
                    </div>
                </div>
       
                <p className="scrolldown">
                      <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
                </p>
            </header> {/* end s-header */}

          
          </React.Fragment>
        );
      }
}