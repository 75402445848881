import React, { Component } from 'react';
export default class About extends Component {
    render(){
        let resumeData = this.props.resumeData;
        return(
            <section id="about" >
                <div className="row">
                    <div className="three columns">
                        <img className="profile-pic" src="images/me.jpg" alt="" />
                    </div>

                    <div className="nine columns main-col">
                        <h2>About Me</h2>
                        <p>{resumeData.aboutme1}</p>
                        <p>{resumeData.aboutme2}</p>
                        <hr />
                        <div className="row">
                            <div className="columns contact-details">
                                <h2>Contact Details</h2>
                                <p className="address">
                                    <span style={{fontSize:"24px"}}>{resumeData.name}</span>
                                    <br></br>
                                    <span>{resumeData.address}</span>
                                    <br></br>
                                    <span>{resumeData.phone}</span>
                                    <br></br>
                                    <a className="mailtoui" href="mailto:shivamkrsarin1996@gmail.com">shivamkrsarin1996@gmail.com</a>
                                </p>
                            </div>
                            <div className="columns download">
                  <p>
                     <p><br/><br/><a href={resumeData.resumeDownload} className="button" target="_blank"><i className="fa fa-download"></i>Download Resume</a></p>
                  </p>
               </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}