let resumeData = {
    "imagebaseurl":"https://shivam-sareen.github.io",
    "name": "Shivam Sareen",
    "role": "Software Developer",
    "linkedinId":"https://www.linkedin.com/in/shivam-sareen/",
    "phone":"(682)283-6516",
    "roleDescription": " I like dabbling in various parts of software development and love to combine my passion for learning and adapting to new skills with my software development skills to continue building personalized learning products for people which can be used to solve real-life problems to make people's life simpler with the help of technology.",
    "socialLinks":[
      {
        "name":"phone",
        "url":"tel:+1(682) 283-6516",
        "className":"fa fa-phone-square"
      },
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/shivam-sareen/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/shivamkrsarin1996",
          "className":"fa fa-github"
        },
      ],
      "aboutme1":"A seasoned and passionate software engineer currenlty working at Amazon developing the Prime Video Service, driven by a desire to combine my love of continuous learning with technical expertise to create innovative products and personalized customer experiences. ",
      "aboutme2":"I love combining my passion for learning and adapting to new skills with my software development skills to continue building personalized learning products for people that can be used to solve real-life problems and simplify people's lives with the help of technology.",
      "aboutme3":"I'm looking for the next exciting opportunity to build and develop solutions to help solve problems",
      "address":"Fremont, California, United States",
      "resumeDownload":"https://tinyurl.com/ShivamKumarSareen-Resume",
      "work":[
        {
          "CompanyName":"Amazon",
          "specialization":"Software Engineer",
          "StartDate":"Feb 2022",
          "EndDate":"Present",
          "Achievements1":"1. Developed and maintained a highly scalable distributed backend service for Prime Video detail page, responsible for rendering video title information, serving concurrent traffic of over 25,000 TPS.", 
          "Achievements2":"2. Architected and created an automated AWS based solution for reducing manual tasks for service scaling & load testing efforts by 67%.",
          "Achievements3":"3. Reduced service response time for fetching the service response data by 55% by implementing client-side local caching.",
          "Achievements4":"4. Engineered centralized SDK libraries, Infrastructures, and APIs for multiple Prime Video’s mobile client edge services; reducing resource consumptions by 40%, and redundant functionalities; significantly improving performance and scalability across services.",
          "Achievements5":"5. Enhanced service response availability by introducing resiliency for client edges by building and returning cached CDN responses, thus reducing the overall hardware scaling requirements for the overall Prime Video service by 10%.",
          "Achievements6":"6. Spearheaded & launched a real-time dashboard to detect payment processor outages for Amazon.com retail website, preventing losses incurred over 1 million/hour by identifying and alerting teams to outages swiftly."
        },
        {
          "CompanyName":"Cummins",
          "specialization":"Software Engineering Intern",
          "StartDate":"May 2021",
          "EndDate":"Aug 2021",
          "Achievements1":"1. Optimized the code for a centralized Salesforce lightning app providing user identity & access to 30+ apps integrated to provide IAM authentication with Active Directories and LDAP.", 
          "Achievements2":"2. Refactored the overall application model & revamped process flows to reduce the application load by 20%.",
          "Achievements3":"3. Managed & collaborated with a team of 4 interns for a Hackathon and designed a detailed solution on how IT can be more self-service by automating the applications using an intelligent RPA-based solution"
        },
        {
          "CompanyName":"The University of Texas at Arlington",
          "specialization":"Graduate Student Assistant",
          "StartDate":"Oct 2020",
          "EndDate":"Dec 2021",
          "Achievements1":"1. Managed and consolidated over 7000 records within the HubSpot CRM database to clean-up duplicate records and updated the status of applicants for the School of Social Work Department.", 
          "Achievements2":"2. Upgraded the admission platform from the manual application tracking process to a one-place automated CRM based solution using HubSpot, reducing the total time to manage the applications by 90%."
        },
        {
          "CompanyName":"Deloitte Consulting",
          "specialization":"Software Engineer",
          "StartDate":"Aug 2017",
          "EndDate":"Jul 2019",
          "Achievements1":"1. Designed and developed 5 applications over the Cloud-based CRM - Salesforce.com platform as PaaS using Apex Classes, form builders, email templates and triggers.", 
          "Achievements2":"2. Performed Unit and Regression Testing and debugging for an application to suggest improvements that reduced the time by 90% to handle bulk user-data.",
          "Achievements3":"3. Managed & coordinated administrative tasks for 8 apps as a Salesforce Administrator for over 100k users globally.",
          "Achievements4":"4. Proposed & Implemented a Proof-of-Concept(POC) to design DevOps deployment architecture including setting up environments, documenting and migrating code with a 95% acceptance rate from the client."
        },
        {
            "CompanyName":"Deloitte Consulting",
            "specialization":"Software Engineering Intern",
            "StartDate":"Jun 2017",
            "EndDate":"Aug 2017",
            "Achievements1":"1. Engineered and launched 5 cloud-based applications on Salesforce CRM platform using Apex, Visualforce, and triggers, reducing customer query resolution time by 25%.", 
            "Achievements2":"2. Architected and executed a Proof-of-Concept (POC) for DevOps deployment using Azure, establishing development environments, detailed documentation, achieving a 95% client acceptance rate & enhancing code migration efficiency.",
            "Achievements3":"3. Performed Unit & regression testing and debugging for an application to suggest improvements reducing the time to manage bulk user data by 90%.",
            "Achievements4":"4. Managed & coordinated 8 apps as a Salesforce Administrator for over 100k users globally.",
            "Achievements5":"5. Reduced deployment time by 70% by creating & maintaining sandboxes ensuring Continuous Integration & Deployment."
        }
      ],
      "education":[
        {
          "UniversityName":"The University of Texas at Arlington",
          "specialization":"Master's in Computer Science",
          "MonthOfPassing":"Dec",
          "YearOfPassing":"2021",
          "RelevantCourses":"Design & Analysis of Algorithms, Data Structures, Advanced Automation Testing, Distributed Systems"
        },
        {
          "UniversityName":"Savitribai Phule Pune University",
          "specialization":"Bachelor's in Computer Applications",
          "MonthOfPassing":"Apr",
          "YearOfPassing":"2017",
          "RelevantCourses":"Data Structures, Advanced Web Development, Advanced Relational Database, Computer Networks"
        }
      ],
      
      "skillsDescription":"",
      "skills":[
        {
            "skillname":"Java"
          },
          {
            "skillname":"Python"
          },
          {
            "skillname":"Salesforce CRM"
          },
          {
            "skillname":"HubSpot CRM"
          },
          {
            "skillname":"DevOps"
          },
          {
            "skillname":"Software Development"
          },
        {
          "skillname":"JavaScript"
        },
        {
          "skillname":"Web Development"
        },
        {
          "skillname":"Python"
        },
        {
            "skillname":"Database"
          }        
      ],
      "portfolio":[
        {
          "name":"Chat Application",
          "description":"Java Based Application",
          "img":"images/portfolio/chat-app.jpg",
          "url":"https://github.com/shivamkrsarin1996/Client-server-message-application"
        },
        {
          "name":"Cruise-Event-Management-System",
          "description":"MVC based web application",
          "img":"images/portfolio/cruise-ship-1.jpg",
          "url":"https://github.com/shivamkrsarin1996/Cruise-Event-Management-System"
        },
        {
            "name":"2- phase locking protocol",
            "description":"Locking protocol between processes in Database",
            "img":"images/portfolio/lock-protocol.jpg",
            "url":"https://github.com/shivamkrsarin1996/2-Phase-Locking-protocol"
        },
        {
            "name":"Railway Management System",
            "description":"MVC based web application",
            "img":"images/portfolio/rail-management.jpeg",
            "url":"https://github.com/shivamkrsarin1996/DB1-Railway-Management-System"
        }
      ],
      "testimonials":[
        {
          "description":"Shivam and I worked at Deloitte, he's been a colleague for 2 years during his tenure. I've had the opportunity to closely see him work in different roles at Deloitte viz. as a Salesforce Developer, DevOps Engineer, Salesforce Admin. He is an extremely talented individual having very strong technical skills. He is passionate on ML/AI along with a keen focus on achieving business/client-success. He proved his mettle in problem solving and logical thinking time and again. I can surely say with great certainty that he will definitely be an asset for any organization !",
          "name":"Ashin Guha Majumder"
        },
        {
          "description":"He is a very talented and enthusiast person. He is also a very good team player. He is always up for learning new things and implementing them in a prominent way. Even though he worked with a different team he became a point of contact person for everyone in my team for all kind of technical queries in a very short time period!! It is very good to have such a dedicated resource in the team who is always up for new responsibilities!!",
          "name":"Saif Khan"
        },
        {
            "description":"Young, dynamic and hardworking person. Shivam always takes his work seriously and try to resolve it.",
            "name":"Mangesh Abhang"
          }
      ]
    }
  export default resumeData