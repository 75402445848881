import React, { Component } from 'react';
export default class Header extends Component {
    render() {
        let resumeData = this.props.resumeData;
        return (
        <section id="contact" >
            <div className="row s-contact__header">
                <div className="column large-12">
                    <h2 className="section-header-allcaps" style={{"color":"rgba(248, 99, 99, 0.911)"}} >Reach out to Me</h2>
                </div>
            </div>
            <div className="row s-contact__content">
                <div className="column large-7 medium-12">
                    <h4 className="huge-text">
                        <br/>
                        <p>
                        Have a new project in mind? Let's collaborate and build something awesome. 
                        Let's turn that idea to an even greater product :)
                        </p>
                    </h4>
                </div>
                <div className="column large-4 medium-12">
                    <div className="row contact-infos">
                        <div className="column large-12 medium-6 tab-12">
                            <div className="contact-block">
                                <h5 className="contact-block__header">
                                   <u style={{"color":"rgba(208, 139, 99, 0.911)"}}> Email </u>
                                </h5>
                                <p className="contact-block__content">
                                    <a className="mailtoui" href="mailto:shivamkrsarin1996@gmail.com" style={{fontSize:"20px"}}>shivamkrsarin1996@gmail.com</a>
                                </p>
                            </div> {/* end contact-block */}
                        </div>
                        <div className="column large-12 medium-6 tab-12">
                            <div className="contact-block">
                                <h5 className="contact-block__header">
                                    <u style={{"color":"rgba(208, 139, 99, 0.911)"}}>Phone </u>
                                </h5>
                                <p className="contact-block__content">
                                    <a href="tel:+1(682) 283-6516" style={{fontSize:"20px"}}>+1(682)283-6516</a>
                                </p>
                            </div> {/* end contact-block */}
                        </div>
                    </div> 
                </div>
            </div> 
        </section> 
        );
    }
}
    
